import React from 'react'
import Page from '../../components/page'
import withRoot from '../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import { Link, navigate } from 'gatsby'
import {
  Typography,
  Button,
} from '@material-ui/core'

const styles = theme => ({
  parag: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.light,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
})

@withRoot
@withStyles(styles)
export default class CantFind extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <Page toggleTheme={this.props.toggleTheme} title="Can't find it?">
        <Typography variant="h4">You did not find your clockface on Fitbit Gallery?</Typography>
        <Typography className={classes.parag}>
          Please note that we have more than 5000 designs on FitFace.xyz and we can&apos;t push them all in the Fitbit gallery.
          Only a few are available in the Fitbit gallery.
          But you can access all your purchases, the free ones and try demos from our our <b>generic clockface</b>. :-)
        </Typography>
        <Typography className={classes.parag}>
          The FitFace clockface is a unique clockface that permits to manage and install all your FitFace purchases
          and the free designs from FitFace too. You will also be able to try any paid clockface for 15 minutes.
        </Typography>
        <Typography className={classes.parag}>
          No more unlock needed. You just login once and your done.
        </Typography>
        <Typography className={classes.parag}>
          Follow the detailed procedure <Link className={classes.link} to="/user/getstarted">here</Link>
        </Typography>
        <Button onClick={() => navigate('/')}>Back to FAQ</Button>
      </Page>
    )
  }
}
